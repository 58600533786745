import '../stylesheets/main.scss';

// slick
// こちらはCSSファイル読み込みのimport
import 'slick-carousel/slick/slick.css';
// こちらはメソッド読み込みのimport
import slick from 'slick-carousel';

// sp modal
import modaal from 'modaal';
import 'modaal/dist/css/modaal.css';

$('.info').modaal({
	overlay_close: true, //モーダル背景クリック時に閉じるか
	before_open: function () {
		// モーダルが開く前に行う動作
		$('html').css(
			'overflow-y',
			'hidden'
		); /*縦スクロールバーを出さない*/
	},
	after_close: function () {
		// モーダルが閉じた後に行う動作
		$('html').css(
			'overflow-y',
			'scroll'
		); /*縦スクロールバーを出す*/
	},
});

// slick
$('.slider').slick({
	autoplay: true,
	infinite: true,
	speed: 500,
	prevArrow: '<div class="slick-prev"></div>',
	nextArrow: '<div class="slick-next"></div>',
	dots: true,
});

// fontawesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

// ハンバーガー
$('.openbtn').on('click', function () {
	if (
		$('.sp-nav-content').css('display') ===
		'block'
	) {
		$('.sp-nav-content').slideUp('1000');
	} else {
		$('.sp-nav-content').slideDown('1000');
	}
	// アイコン切り替え
	$(this).toggleClass('active');
});

// アコーディオン
$('.main-nav-ttl').on('click', function () {
	$(this).children('.sp-sub').slideToggle();
	$('.main-nav-ttl')
		.not($(this))
		.children('.sp-sub')
		.slideUp();
	//アイコン
	$(this).toggleClass('active');
});

// 検索窓
$('.sp-search-box').on('click', function () {
	if (
		$('.sp-search-input').css('display') ===
		'block'
	) {
		$('.sp-search-input').slideUp('1000');
	} else {
		$('.sp-search-input').slideDown('1000');
	}
});

// 地図

$('#btn-hokkaido').on('click', function () {
	$('#modal-hokkaido').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-tohoku').on('click', function () {
	$('#modal-tohoku').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-kanto').on('click', function () {
	$('#modal-kanto').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-tokai').on('click', function () {
	$('#modal-tokai').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-kinki').on('click', function () {
	$('#modal-kinki').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-shikoku').on('click', function () {
	$('#modal-shikoku').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-kyusyu').on('click', function () {
	$('#modal-kyusyu').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-chugoku').on('click', function () {
	$('#modal-chugoku').fadeIn();
	$('.map-white').fadeIn();
});
$('#btn-hokushinetsu').on('click', function () {
	$('#modal-hokushinetsu').fadeIn();
	$('.map-white').fadeIn();
});

// 地図の閉じるボタン
$('.modal-btn-close').on('click', function () {
	$('.modal-box').fadeOut();
	$('.map-white').fadeOut();
});

// 地図の領域外クリックで閉じる
$(document).on('click', function (event) {
	if (
		!$(event.target).closest('.map-btn').length
	) {
		$('.modal-box').fadeOut();
		$('.map-white').fadeOut();
	}
});

// サイドナビ
$('.sidenav-title').on('click', function () {
	$('.sidenav-list').slideUp(500);

	var findElm = $(this).next('.sidenav-list');

	if ($(this).hasClass('close')) {
		$(this).removeClass('close');
	} else {
		$('.close').removeClass('close');
		$(this).addClass('close');
		$(findElm).slideDown(500);
	}
});

// $(window).on('load', function () {
// 	$(
// 		'.accrodion-area li:first-of-type div'
// 	).addClass('open');
// 	$('.open').each(function (index, element) {
// 		var Title = $(element).children(
// 			'.sidenav-title'
// 		);
// 		$(Title).addClass('.close');
// 		var Box = $(element).children(
// 			'.sidenav-list'
// 		);
// 		$(Box).slideDown(100);
// 	});
// });

$(window).on('load', function () {
	var gyoji = $('body').hasClass('gyoji-page');
	var localfoods = $('body').hasClass(
		'localfoods-page'
	);
	var sidorei = $('body').hasClass(
		'sidorei-page'
	);
	var sidorei = $('body').hasClass(
		'sidorei-page'
	);
	var aboutGaiyo = $('body').hasClass(
		'about-gaiyo-page'
	);
	var aboutSoshiki = $('body').hasClass(
		'about-soshiki-page'
	);
	var supportingmember = $('body').hasClass(
		'about-supporting-page'
	);
	var activitiesActivities = $('body').hasClass(
		'activities-activities-page'
	);
	var activitiesPublication = $('body').hasClass(
		'activities-publication-page'
	);
	var activitiesBreakfast = $('body').hasClass(
		'activities-breakfast-page'
	);
	var activitiesKyodosyokuIndex = $(
		'body'
	).hasClass('kyodosyoku-index-page');
	if (gyoji) {
		$(
			'.accrodion-area li:nth-of-type(2) div'
		).addClass('open');
	} else if (localfoods) {
		$(
			'.accrodion-area li:first-of-type div'
		).addClass('open');
	} else if (sidorei) {
		$(
			'.accrodion-area li:first-of-type div'
		).addClass('open');
	} else if (aboutGaiyo) {
		$(
			'.accrodion-area li:first-of-type div'
		).addClass('open');
	} else if (aboutSoshiki) {
		$(
			'.accrodion-area li:nth-of-type(2) div'
		).addClass('open');
	} else if (supportingmember) {
		$(
			'.accrodion-area li:nth-of-type(3) div'
		).addClass('open');
	} else if (activitiesActivities) {
		$(
			'.accrodion-area li:nth-of-type(1) div'
		).addClass('open');
	} else if (activitiesPublication) {
		$(
			'.accrodion-area li:nth-of-type(2) div'
		).addClass('open');
	} else if (activitiesBreakfast) {
		$(
			'.accrodion-area li:nth-of-type(3) div'
		).addClass('open');
	} else if (activitiesKyodosyokuIndex) {
		$(
			'.accrodion-area li:nth-of-type(2) div'
		).addClass('open');
	}

	$('.open').each(function (index, element) {
		var Title = $(element).children(
			'.sidenav-title'
		);
		$(Title).addClass('.close');
		var Box = $(element).children(
			'.sidenav-list'
		);
		$(Box).slideDown(100);
	});
});

// 会員ページ タブ
function GethashID(hashIDName) {
	if (hashIDName) {
		$('.tab li')
			.find('a')
			.each(function () {
				var idName = $(this).attr('href');
				if (idName == hashIDName) {
					var parentElm = $(this).parent();
					$('.tab li').removeClass('active');
					$(parentElm).addClass('active');
					$('.area').removeClass('is-active');
					$(hashIDName).addClass('is-active');
				}
			});
	}
}
$('.tab a').on('click', function () {
	var idName = $(this).attr('href');
	GethashID(idName);
	return false;
});
$(window).on('load', function () {
	$('.tab li:first-of-type').addClass('active');
	$('.area:first-of-type').addClass('is-active');
	var hashName = location.hash;
	GethashID(hashName);
});

// ページ内リンク
$('.page-link a[href*="#"]').click(function () {
	var elmHash = $(this).attr('href');
	var pos = $(elmHash).offset().top;
	$('body,html').animate({scrollTop: pos}, 500);
	return false;
});
